//TODO: pass delimiters
/*@ngInject*/ function FullCardController ($log) {
  this.parentNameMap = {};
  this.fieldsOrder = [];
  this.fieldValueMap = {};

  this.$onChanges = (changedObj) => {
    if(this.fieldNames) {
      this.parentNameMap = {};
      this.fieldsOrder = [];
      this.fieldValueMap = {};

      // TODO: make sure search component doesn't mutate results (adds ! instad of null)
      this.fieldNames.forEach((fieldName, index) => {
        if(fieldName.indexOf('.') > -1) {
          const firstIndex = fieldName.split('.')[0];

          if(this.parentNameMap[firstIndex]) {
            this.parentNameMap[firstIndex].push(fieldName);
          } else {
            this.parentNameMap[firstIndex] = [fieldName];

            this.fieldsOrder.push([firstIndex, this.parentNameMap[firstIndex]]);
          }
        } else {
          if(this.parentNameMap[fieldName]) {
            $log.error('fieldName ' + fieldName + ' not unique');
          }

          this.parentNameMap[fieldName] = [fieldName];

          this.fieldsOrder.push([fieldName, null]);
        }

        this.fieldValueMap[fieldName] = Array.isArray(this.result[fieldName]) ? this.result[fieldName].map( (alleleVal) => {
          return alleleVal.map( (positionVal) => {
            return Array.isArray(positionVal) ? positionVal.map((val) => {
              return val || "!";
            }).join(';') : positionVal;
          }).join('|');
        }).join('/') : (this.result[fieldName] || "!");
      });
    }
  };
}

angular.module('sq.jobs.results.search.fullCard.component', [])
.component('sqSearchFullCard', {
  bindings: {
    fieldNames: '<',
    result: '<',
    onBack: '&',
  },
  templateUrl: 'jobs/results/search/jobs.results.search.full-card.tpl.html',
  controller: FullCardController,
});
